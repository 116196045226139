.App {
  text-align: center;
  background: url("./assets//images/background.jpg") no-repeat center center;
  -webkit-background-attachment: fixed;
  background-attachment: fixed;
  background-color: #222;
  background-size: cover;
	padding: 0;
	position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.App::before {
  content: "";
  background-color: rgba(26, 30, 35, 0.932);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
